<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main pdf">
			<embed type="text/html" src="https://al-cex-pub.s3.ap-southeast-1.amazonaws.com/AlcexWhitePaperVer%201.0.pdf"
				width="100%" height="800">
			<p class="arrow">
				<a href="https://raw.githubusercontent.com/Alcexpublic/Alcex/master/AlcexWhitePaperVer%201.0.pdf"
					target="_blank">下载白皮书(或在白皮书上鼠标右键 ->; "另存为...")</a>
			</p>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/Head.vue'
	export default {
		name: 'Home',
		components: {
			Head
		},
		data() {
			return {

			};
		},
		created() {

		},
		mounted() {

		},
		methods: {

		}
	}
</script>
<style>
	.pdf {
		background-color: rgb(82, 86, 89);
	}

	.pdf p {
		text-align: center;
		padding: 10px 0;
	}

	.pdf p a {
		color: #fff;
	}
</style>
